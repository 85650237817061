import IconButton, { IconButtonProps } from "@material-ui/core/IconButton"
import FavoriteIcon from "@material-ui/icons/Favorite"
import React from "react"
import styled from "styled-components"
import { simpleTransition } from "../../theme/globalStyles"
import { ProductFragment } from "../../__generated__/graphql-gatsby"
import { ProductFragmentFragment as PreviewProductFragment } from "../../__generated__/graphql-wp-sdk"
import { useFavContext } from "../context/favContext"

interface FavIconProps {
  product: ProductFragment | PreviewProductFragment
  small?: boolean
  className?: string
}

interface IIconButton extends IconButtonProps {
  selected: boolean
}

export const Icon = styled(IconButton)<IIconButton>`
  z-index: 10;
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme, selected }) =>
    selected ? theme.palette.tertiary.main : theme.palette.primary.contrastText};
  border: ${({ theme }) => `${theme.spacing(0.4)}px solid ${theme.palette.primary.dark}`};
  ${simpleTransition};

  :hover {
    background-color: ${({ theme }) => theme.palette.primary.dark};
    border: ${({ theme }) => `${theme.spacing(0.4)}px solid ${theme.palette.primary.main}`};
  }
`

const FavButton: React.FC<FavIconProps> = ({ product, small, className }) => {
  const { favorites, dispatch, ACTIONS } = useFavContext()
  const isSelected = favorites.favorites.map(f => f.id).includes(product.id)

  const updateFav = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    e.stopPropagation()

    if (isSelected) {
      dispatch({ type: ACTIONS.REMOVE, id: product.id })
    } else {
      dispatch({
        type: ACTIONS.ADD,
        id: product.id,
        name: String(product.title),
        price: Number(product.product_fields?.prijs),
      })
    }
  }

  return (
    <Icon
      onClick={updateFav}
      selected={isSelected}
      size={!small ? "medium" : "small"}
      className={className}
    >
      <FavoriteIcon />
    </Icon>
  )
}

export default FavButton
