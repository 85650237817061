import ButtonBase, { ButtonBaseProps } from "@material-ui/core/ButtonBase"
import Typography from "@material-ui/core/Typography"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import { animated } from "react-spring"
import styled, { css } from "styled-components"
import { getLinkProps } from "../../helpers"
import useBoop from "../../hooks/useBoop"
import { largeShadow, shadow, simpleTransition, xsMediaQuery } from "../../theme/globalStyles"
import { ProductFragment } from "../../__generated__/graphql-gatsby"
import { ProductFragmentFragment as PreviewProductFragment } from "../../__generated__/graphql-wp-sdk"
import FavButton from "../favButton"

interface IProps {
  product: Maybe<ProductFragment | PreviewProductFragment>
  small?: boolean
}

const CardWrapper = styled(({ small, ...rest }) => <ButtonBase {...rest} />)<
  ButtonBaseProps & { small: Maybe<boolean> }
>`
  position: relative;
  display: block;
  width: 100%;
  max-width: ${({ small }) => (small ? 240 : 480)}px;
  ${({ small }) => (small ? shadow : largeShadow)};
  ${simpleTransition};
  border: ${({ theme }) => `${theme.spacing(0.5)}px solid ${theme.palette.tertiary.main}`};
  overflow: hidden;
  margin: ${({ theme, small }) => `${theme.spacing(6)}px ${theme.spacing(small ? 10 : 6)}px`};

  ${({ theme, small }) =>
    xsMediaQuery(css`
      margin: ${`${theme.spacing(2)}px ${theme.spacing(small ? 6 : 1)}px}`};
    `)}

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0);
    ${simpleTransition};
  }

  :hover {
    .overlay {
      background-color: rgba(0, 0, 0, 0.09);
    }
  }
`

const Info = styled.div<{ small: boolean }>`
  position: relative;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.tertiary.main};
  color: ${({ theme }) => theme.palette.tertiary.contrastText};
  padding: ${({ theme, small }) => `${theme.spacing(small ? 1.5 : 3)}px ${theme.spacing(1)}px`};
  z-index: 10;
`

const Price = styled.div`
  height: 110px;
  width: 250px;
  transform: rotate(-45deg) translateY(-12px);
  padding-top: ${({ theme }) => theme.spacing(2)}px;
  position: absolute;
  bottom: 50px;
  right: -85px;
  z-index: 1;
  background-color: ${({ theme }) => theme.palette.tertiary.main};
  color: ${({ theme }) => theme.palette.tertiary.contrastText};
`

const FavoriteButton = styled(FavButton)`
  position: absolute;
  top: ${({ theme }) => theme.spacing(2)}px;
  right: ${({ theme }) => theme.spacing(2)}px;
`

const ProductCard: React.FC<IProps> = ({ product, small }) => {
  if (!product) return null

  const { title, uri, product_fields } = product

  if (!product_fields?.afbeeldingen || !uri) return null

  const [style, trigger] = useBoop({ sy: 0.6 })
  const afbeelding = product_fields.afbeeldingen[0]?.afbeelding
  const altText = afbeelding?.__typename === "WpMediaItem" ? String(afbeelding.altText) : "preview"

  return (
    <CardWrapper {...getLinkProps(uri)} focusRipple onMouseEnter={trigger} small={small}>
      <FavoriteButton product={product} small={small} />

      {afbeelding?.__typename === "WpMediaItem" && (
        <GatsbyImage
          image={afbeelding?.localFile?.childImageSharp?.gatsbyImageData}
          alt={String(altText)}
        />
      )}

      {afbeelding?.__typename === "MediaItem" && (
        <img src={String(afbeelding.mediaItemUrl)} alt={altText} style={{ width: "100%" }} />
      )}

      {small ? null : (
        <Price>
          <Typography
            variant="h3"
            component="p"
            align="center"
            style={{ transform: "rotate(45deg) translateX(12px)" }}
          >
            € {product_fields.prijs}
          </Typography>
        </Price>
      )}

      <Info small={Boolean(small)}>
        <animated.div style={style}>
          <Typography variant={small ? "body1" : "h2"} component="h1" align="center">
            {title}
          </Typography>
        </animated.div>
      </Info>
      <div className="overlay" />
    </CardWrapper>
  )
}

export default ProductCard
