import { Typography } from "@material-ui/core"
import React from "react"
import styleToCss from "style-object-to-css-string"
import styled, { css } from "styled-components"

type keys = "h1" | "h2" | "h3" | "h4" | "h5" | "h6"

const materialToCssHeaders = (key: keys) => css`
  ${({ theme }) => styleToCss(theme.typography[key])};
  color: ${({ theme }) => theme.palette.secondary.main};
  margin-top: 0;

  @media (min-width: 600px) {
    ${({ theme }) =>
      theme.typography[key]["@media (min-width:600px)"] &&
      styleToCss(theme.typography[key]["@media (min-width:600px)"])};
  }
  @media (min-width: 960px) {
    ${({ theme }) =>
      theme.typography[key]["@media (min-width:960px)"] &&
      styleToCss(theme.typography[key]["@media (min-width:960px)"])};
  }
  @media (min-width: 1280px) {
    ${({ theme }) =>
      theme.typography[key]["@media (min-width:1280px)"] &&
      styleToCss(theme.typography[key]["@media (min-width:1280px)"])};
  }
`

const WpContent = styled(({ ...props }) => <Typography component="div" {...props} />)`
  .inline-gatsby-image-wrapper {
    span {
      display: block;
    }
  }

  li {
    max-width: 600px;
  }

  blockquote {
    margin-top: ${({ theme }) => theme.spacing(3)}px;
    margin-bottom: ${({ theme }) => theme.spacing(3)}px;
    margin-left: ${({ theme }) => theme.spacing(4)}px;
    border-left: ${({ theme }) => `${theme.spacing(1)}px solid ${theme.palette.tertiary.main}`};
    padding: ${({ theme }) => `${theme.spacing(0.5)}px ${theme.spacing(4)}px`};
  }
  /* 
  * =Material UI
  */
  h1 {
    ${materialToCssHeaders("h1")};
  }
  h2 {
    ${materialToCssHeaders("h2")};
  }
  h3 {
    ${materialToCssHeaders("h3")};
  }
  h4 {
    ${materialToCssHeaders("h4")};
  }
  h5 {
    ${materialToCssHeaders("h5")};
  }
  h6 {
    ${materialToCssHeaders("h6")};
  }
  /*
  * =Custom WordPress Mui Mix
  */
  a {
    color: ${({ theme }) => theme.palette.primary.main};
    transition: all ${({ theme }) => theme.transitions.duration.short}ms ease-in-out;
    cursor: pointer;
  }
  a:hover,
  a:focus {
    color: ${({ theme }) => theme.palette.primary.dark};
  }

  /* 
  * =WordPress Core
  */
  .alignnone {
    margin: 5px 20px 20px 0;
  }

  .aligncenter,
  div.aligncenter {
    display: block;
    margin: 5px auto 5px auto;
  }

  .alignright {
    float: right;
    margin: 5px 0 20px 20px;
  }

  .alignleft {
    float: left;
    margin: 5px 20px 20px 0;
  }

  a img.alignright {
    float: right;
    margin: 5px 0 20px 20px;
  }

  a img.alignnone {
    margin: 5px 20px 20px 0;
  }

  a img.alignleft {
    float: left;
    margin: 5px 20px 20px 0;
  }

  a img.aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .wp-caption {
    background: #fff;
    border: 1px solid #f0f0f0;
    max-width: 96%; /* Image does not overflow the content area */
    padding: 5px 3px 10px;
    text-align: center;
  }

  .wp-caption.alignnone {
    margin: 5px 20px 20px 0;
  }

  .wp-caption.alignleft {
    margin: 5px 20px 20px 0;
  }

  .wp-caption.alignright {
    margin: 5px 0 20px 20px;
  }

  .wp-caption img {
    border: 0 none;
    height: auto;
    margin: 0;
    max-width: 98.5%;
    padding: 0;
    width: auto;
  }

  .wp-caption p.wp-caption-text {
    font-size: 11px;
    line-height: 17px;
    margin: 0;
    padding: 0 4px 5px;
  }

  /* Text meant only for screen readers. */
  .screen-reader-text {
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute !important;
    width: 1px;
    word-wrap: normal !important; /* Many screen reader and browser combinations announce broken words as they would appear visually. */
  }

  .screen-reader-text:focus {
    background-color: #eee;
    clip: auto !important;
    clip-path: none;
    color: #444;
    display: block;
    font-size: 1em;
    height: auto;
    left: 5px;
    line-height: normal;
    padding: 15px 23px 14px;
    text-decoration: none;
    top: 5px;
    width: auto;
    z-index: 100000;
    /* Above WP toolbar. */
  }
`

export default WpContent
