import Container from "@material-ui/core/Container"
import Paper from "@material-ui/core/Paper"
import { graphql, PageProps } from "gatsby"
import React from "react"
import styled, { css } from "styled-components"
import { useTemplateType } from "../components/layout/context"
import { Square, SubMenuItem, SubMenuList } from "../components/layout/header/menu/menuItem"
import ContactBanner from "../components/pageBuilder/contactBanner"
import TextBlock from "../components/pageBuilder/textBlock"
import ProductCard from "../components/productCard"
import SEO from "../components/SEO"
import { fakeBlock, getLinkProps } from "../helpers"
import { xsMediaQuery } from "../theme/globalStyles"
import { TemplateCollectieQueryQuery, WpCollectie } from "../__generated__/graphql-gatsby"

type IProps = PageProps<TemplateCollectieQueryQuery>

type IProducts = {
  products: ({
    __typename?: "WpCollectie" | undefined
  } & Pick<WpCollectie, "name" | "id" | "uri">)[]
}

const StyledContainer = styled(Container)`
  max-width: ${({ theme }) => theme.breakpoints.values.xxl}px;
`

const Wrapper = styled.div`
  padding: ${({ theme }) => theme.spacing(8)}px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  ${({ theme }) =>
    xsMediaQuery(css`
      padding: ${`${theme.spacing(2)}px ${theme.spacing(1)}px}`};
    `)};
`

const SubMenu = styled(Paper)`
  width: 100%;
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.contrastText};
  border-radius: 0;

  > ul {
    width: 100%;
    display: flex;
    justify-content: center;

    a {
      font-weight: 500;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
      display: none;
    }
  }
`

const Menu: React.FC<IProducts> = ({ products }) => (
  <SubMenu>
    <SubMenuList>
      <SubMenuItem {...getLinkProps("/verhuur-pallet-meubelen/")}>Alle categorieën</SubMenuItem>
      <Square />
      {products.map((product, i) => [
        <SubMenuItem key={product.id} {...getLinkProps(String(product.uri))}>
          {product.name}
        </SubMenuItem>,
        i < products.length - 1 && <Square key={`sub-square-${i}`} />,
      ])}
    </SubMenuList>
  </SubMenu>
)

const Page: React.FC<IProps> = ({ data: { wpCollectie, allWpCollectie } }) => {
  const [, setTemplate] = useTemplateType()
  setTemplate("collection")

  const { uri, seo } = { ...wpCollectie }
  const { nodes: products } = { ...wpCollectie?.producten }
  const { nodes: others } = { ...allWpCollectie }

  return (
    <>
      <SEO
        title={seo?.title}
        description={seo?.metaDesc}
        ogTitle={seo?.opengraphTitle}
        ogDescription={seo?.opengraphDescription}
        siteName={seo?.opengraphSiteName}
        canonical={uri}
      />
      <TextBlock
        bgColor="dark-blue"
        content={`<h1 align="center" style="color: white; margin-bottom: -18px; font-size: 2.5rem">${wpCollectie?.name}</h1>`}
      />
      <Menu products={others} />
      <StyledContainer>
        <Wrapper>
          {products?.map(product => (
            <ProductCard key={product?.id} product={product} />
          ))}
        </Wrapper>
      </StyledContainer>
      <Menu products={others} />
      <ContactBanner block={fakeBlock} />
    </>
  )
}

export default Page

export const query = graphql`
  fragment TaxonomySEO on WpTaxonomySEO {
    metaDesc
    title
    opengraphSiteName
    opengraphTitle
    opengraphDescription
  }
  fragment ProductImages on WpProduct_ProductFields_afbeeldingen {
    afbeelding {
      __typename
      altText
      mediaDetails {
        width
        height
      }
      localFile {
        publicURL
        childImageSharp {
          gatsbyImageData(aspectRatio: 1)
        }
      }
    }
  }
  fragment Product on WpProduct {
    id
    title
    uri
    product_fields {
      prijs
      afbeeldingen {
        ...ProductImages
      }
    }
  }
  query TemplateCollectieQuery($id: String!) {
    wpCollectie(id: { eq: $id }) {
      id
      name
      uri
      seo {
        ...TaxonomySEO
      }
      producten {
        nodes {
          ...Product
        }
      }
    }
    allWpCollectie(filter: { id: { ne: $id } }) {
      nodes {
        id
        name
        uri
      }
    }
  }
`
