import Container, { ContainerProps } from "@material-ui/core/Container"
import React from "react"
import styled, { css } from "styled-components"
import { smMediaQuery, switchBgColor } from "../../../theme/globalStyles"
import WpContent from "./wpContent"

export type Position = "left" | "right" | "center"

interface IProps {
  content: Maybe<string>
  bgColor: Maybe<string>
  maxWidth?: MuiKey
  position?: Position
  alignTop?: Maybe<boolean>
}

export interface WrapperProps {
  bg: string
  alignTop: Maybe<boolean>
}
export const TextBlockWrapper = styled.div<WrapperProps>`
  display: flex;
  justify-content: center;
  align-items: ${({ alignTop }) => (alignTop ? "flex-start" : "center")};
  padding: ${({ theme }) => theme.spacing(4)}px;
  padding-top: ${({ theme, alignTop }) => theme.spacing(alignTop ? 2 : 5)}px;
  width: 100%;
  height: 100%;
  color: ${({ theme }) => theme.palette.common.black};
  ${({ bg }) => switchBgColor(bg)};
`

export interface StyledContainerProps extends ContainerProps {
  position: Position
}
export const StyledContainer = styled(({ position, ...props }) => (
  <Container {...props} />
))<StyledContainerProps>`
  ${({ position, theme }) => {
    switch (position) {
      case "right":
        return css`
          margin-right: ${theme.spacing(2)}px;
        `
      case "left":
        return css`
          margin-left: ${theme.spacing(2)}px;
        `
      case "center":
      default:
        return css`
          margin-left: auto;
          margin-right: auto;
        `
    }
  }}
  ${smMediaQuery(css`
    margin-left: auto;
    margin-right: auto;
  `)}
`

const TextBlock: React.FC<IProps> = ({
  content,
  bgColor,
  maxWidth = "md",
  position = "center",
  alignTop,
}) => {
  return (
    <TextBlockWrapper bg={String(bgColor)} alignTop={alignTop}>
      <StyledContainer position={position} maxWidth={maxWidth} disableGutters>
        <WpContent dangerouslySetInnerHTML={{ __html: String(content) }} />
      </StyledContainer>
    </TextBlockWrapper>
  )
}

export default TextBlock
