import Container from "@material-ui/core/Container"
import { fade } from "@material-ui/core/styles"
import React from "react"
import styled from "styled-components"
import backgroundImage from "../../../images/contact-banner.jpg"
import { ContactBannerFragment } from "../../../__generated__/graphql-gatsby"
import { ContactBannerFragment as PreviewContactBannerFragment } from "../../../__generated__/graphql-wp-sdk"
import Button from "../../base/button"
import WpContent from "../textBlock/wpContent"

interface IProps {
  block: ContactBannerFragment | PreviewContactBannerFragment
}

const Wrapper = styled.div`
  background-image: ${({ theme }) =>
    `linear-gradient(180deg, ${fade(theme.palette.tertiary.main, 0.88)}, ${
      theme.palette.tertiary.main
    }), url(${backgroundImage})`};
  background-color: ${({ theme }) => theme.palette.tertiary.main};
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;

  padding-top: ${({ theme }) => theme.spacing(7)}px;
  padding-bottom: ${({ theme }) => theme.spacing(7.5)}px;

  .contact-text {
    font-weight: 600;
    font-size: 125%;
  }
`

const InnerWrapper = styled(Container)`
  @media (min-width: 720px) {
    max-width: 720px;
    padding-left: 24px;
    padding-right: 24px;
  }
`

const ButtonWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing(4)}px;
  display: flex;
  justify-content: center;
`

const ContactBanner: React.FC<IProps> = ({ block }) => {
  const { hasAltTekst, text } = block
  return (
    <Wrapper>
      <InnerWrapper>
        {hasAltTekst ? (
          <WpContent dangerouslySetInnerHTML={{ __html: String(text) }} />
        ) : (
          <WpContent style={{ textAlign: "center" }}>
            <h1>Bent u geïnteresseerd?</h1>
            <span className="contact-text">
              Leg uw wensen bij ons neer en samen komen we tot een prachtig en duurzaam resultaat!
            </span>
          </WpContent>
        )}
        <ButtonWrapper>
          <Button color="blue" link="/contact/">
            Neem contact op!
          </Button>
        </ButtonWrapper>
      </InnerWrapper>
    </Wrapper>
  )
}

export default ContactBanner
